$breakpoint-huge: 'screen and (max-width: 1600px)';
$breakpoint-large: 'screen and (max-width: 1200px)';
$breakpoint-medium: 'screen and (max-width: 1024px)';
$breakpoint-small: 'screen and (max-width: 768px)';
$breakpoint-tiny: 'screen and (max-width: 480px)';

$color-white: #ffffff;
$color-black: #0a0a0a;
$color-green: #a0d010;
$color-yellow: #ffefc3;
$color-brand: #008a38;
$color-blue: #42a5f3;
$color-red: #f23030;

@mixin placeholder($color) {
    &::-webkit-input-placeholder {
        color: $color;
    }
    &:-moz-placeholder {
        color: $color;
    }
    &::-moz-placeholder {
        color: $color;
    }
    &:-ms-input-placeholder {
        color: $color;
    }
}
