html,
body {
    height: 100%;
    overflow: hidden;
}
body {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    background-color: $color-white;
    font-family: 'Lato', sans-serif;
    color: $color-black;
    font-size: 14px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
a {
    color: inherit;
    text-decoration: none;
}
img {
    display: flex;
    margin: 0;
}
h1,
h2,
h3,
h4 {
    line-height: 1.2em;
}
input,
select,
button,
textarea {
    border: 0;
    appearance: none;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    border-radius: 0;
    background-color: transparent;
    margin: 0;
    padding: 0;
}
.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 60px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
    @media #{$breakpoint-small} {
        padding: 30px;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 1) 0px,
            rgba(255, 255, 255, 1) 40px,
            rgba(255, 255, 255, 0) 100px
        );
    }
    .logo {
        position: relative;
        height: 40px;
        background-image: url('/images/icons/logo.svg?20240723');
        background-repeat: no-repeat;
        background-size: contain;
    }
}
.loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    pointer-events: none;
    transition: opacity 350ms ease;
    opacity: 0;
    .spinner {
        position: relative;
        width: 80px;
        height: 80px;
        background-image: url('/images/icons/loader.svg');
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0.25;
    }
    &.active {
        pointer-events: all;
        opacity: 1;
    }
}
.summary {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: lighten($color-yellow, 3%);
    top: 60px;
    right: 60px;
    padding: 30px;
    width: 340px;
    border-radius: 6px;
    @media #{$breakpoint-medium} {
        padding: 0 30px;
        width: auto;
        height: 60px;
    }
    @media #{$breakpoint-small} {
        padding: 0 15px;
        top: 30px;
        right: 30px;
        height: 40px;
    }
    .steps {
        position: relative;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
        padding-bottom: 20px;
        margin-bottom: 20px;
        @media #{$breakpoint-medium} {
            display: none;
        }
        .step {
            position: relative;
            display: flex;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
            .number {
                position: relative;
                padding-right: 10px;
                .circle {
                    position: relative;
                    width: 24px;
                    height: 24px;
                    border-radius: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 11px;
                    font-weight: 700;
                    color: rgba(0, 0, 0, 0.25);
                    border: 1px solid rgba(0, 0, 0, 0.25);
                }
            }
            .info {
                position: relative;
                h3 {
                    color: rgba(0, 0, 0, 0.4);
                    font-weight: 700;
                    margin-top: 4px;
                }
                ul {
                    line-height: 1.4em;
                    color: rgba(0, 0, 0, 0.4);
                    margin-top: 2px;
                    font-size: 13px;
                }
            }
            &.active .number .circle {
                background-color: rgba(0, 0, 0, 0.25);
                color: lighten($color-yellow, 3%);
                border: none;
            }
            &.done .number .circle {
                background-color: rgba(0, 0, 0, 0.15);
                border: none;
                background-image: url('/images/icons/done.svg');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 12px auto;
                p {
                    display: none;
                }
            }
            &.active .info h3,
            &.done .info h3 {
                color: rgba(0, 0, 0, 0.6);
            }
        }
    }
    .price {
        position: relative;
        .row {
            position: relative;
            display: flex;
            justify-content: space-between;
            .item {
                position: relative;
                font-weight: 400;
                line-height: 1.4em;
                color: rgba(0, 0, 0, 0.4);
            }
            &:last-child .item {
                font-weight: 700;
                color: rgba(0, 0, 0, 0.6);
            }
        }
        @media #{$breakpoint-medium} {
            .row {
                display: none;
                .item {
                    display: none;
                }
                &:last-child {
                    display: block;
                    .item:last-child {
                        display: block;
                    }
                }
            }
        }
    }
}
.navigation {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 60px;
    background: linear-gradient(0, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
    @media #{$breakpoint-small} {
        padding: 30px;
    }
    .btn {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 0;
        width: 120px;
        height: 40px;
        text-align: center;
        background-color: $color-white;
        border: 1px solid #eeeeee;
        color: #cccccc;
        font-weight: 700;
        cursor: pointer;
        pointer-events: none;
        border-radius: 3px;
        transition: all 350ms ease;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
        &.enabled {
            pointer-events: all;
            color: $color-black;
            border-color: #cccccc;
        }
        &:active {
            background-color: #eeeeee;
        }
    }
    .timeline {
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        padding: 0 30px;
        &:before {
            content: '';
            display: block;
            position: absolute;
            height: 1px;
            background-color: rgba(0, 0, 0, 0.1);
            top: calc(50% - 0.5px);
            left: 30px;
            right: 30px;
            @media #{$breakpoint-small} {
                display: none;
            }
        }
        .tick {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 28px;
            height: 28px;
            background-color: #eeeeee;
            color: #ffffff;
            font-size: 11px;
            border-radius: 100%;
            transition: all 350ms ease;
            &.enabled {
                background-color: #aaaaaa;
            }
            @media #{$breakpoint-medium} {
                display: none;
            }
        }
    }
}
.canvas {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .form {
        position: absolute;
        display: none;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        overscroll-behavior: contain;
        padding: 160px 460px 160px 60px;
        @media #{$breakpoint-medium} {
            padding: 160px 60px 160px 60px;
        }
        @media #{$breakpoint-small} {
            padding: 100px 30px 100px 30px;
        }
        &.active {
            display: block;
        }
        .step {
            position: relative;
            padding-left: 30px;
            border-left: 4px solid #eeeeee;
            @media #{$breakpoint-medium} {
                padding-left: 0;
                border-left: none;
            }
            h2 {
                font-size: 24px;
                font-weight: 900;
                margin-bottom: 20px;
                @media #{$breakpoint-small} {
                    font-size: 20px;
                }
            }
            p {
                position: relative;
                line-height: 1.4em;
                font-weight: 300;
                color: #333333;
                &.large {
                    font-size: 15px;
                }
                a {
                    color: $color-brand;
                    font-weight: 400;
                    border-bottom: 1px solid $color-brand;
                }
            }
            .row {
                position: relative;
                margin-bottom: 30px;
                &:last-child {
                    margin-bottom: 0;
                }
                h3 {
                    font-size: 16px;
                    font-weight: 700;
                    margin-bottom: 20px;
                    span {
                        font-weight: 400;
                        pointer-events: none;
                    }
                    &.req:after {
                        content: '*';
                        color: $color-red;
                        margin-left: 3px;
                    }
                }
                .errors {
                    display: none;
                    margin: -15px 0 20px 0;
                    color: lighten($color-red, 15%);
                    line-height: 1.4em;
                }
                &.errors {
                    h3 {
                        color: $color-red;
                    }
                    .errors {
                        display: block;
                    }
                }
                .hint {
                    margin-bottom: 20px;
                }
                .content {
                    position: relative;
                    margin-bottom: 30px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    input[type='text'],
                    textarea,
                    select {
                        position: relative;
                        border: 1px solid #cccccc;
                        border-radius: 3px;
                        height: 40px;
                        padding: 0 12px;
                        width: 400px;
                        transition: border-color 350ms ease;
                        background-color: $color-white;
                        @include placeholder(#cccccc);
                        &:focus {
                            border-color: #999999;
                        }
                        @media #{$breakpoint-medium} {
                            width: 100%;
                        }
                    }
                    textarea {
                        padding: 12px;
                        height: 200px;
                    }
                    select {
                        background-image: linear-gradient(
                                45deg,
                                transparent 50%,
                                lighten($color-black, 80%) 50%
                            ),
                            linear-gradient(135deg, lighten($color-black, 80%) 50%, transparent 50%);
                        background-position: calc(100% - 20px) calc(17px),
                            calc(100% - 15px) calc(17px);
                        background-size: 5px 5px, 5px 5px;
                        background-repeat: no-repeat;
                        &.placeholder {
                            color: #cccccc;
                        }
                    }
                    input[type='radio'] {
                        position: relative;
                        width: 16px;
                        height: 16px;
                        margin: 1px 0;
                        border-radius: 16px;
                        margin-right: 10px;
                        border: 1px solid #cccccc;
                        transition: all 350ms ease;
                        background-color: $color-white;
                        &:after {
                            content: '';
                            position: absolute;
                            display: block;
                            pointer-events: none;
                            width: 8px;
                            height: 8px;
                            top: 3px;
                            right: 3px;
                            border-radius: 12px;
                            background-color: $color-green;
                            transform: scale(0);
                            transition: all 350ms ease;
                        }
                        &:checked:after {
                            transform: scale(1);
                        }
                    }
                    input[type='checkbox'] {
                        position: relative;
                        width: 18px;
                        height: 18px;
                        border-radius: 3px;
                        margin-right: 10px;
                        border: 1px solid #cccccc;
                        transition: all 350ms ease;
                        background-color: $color-white;
                        &:after {
                            content: '';
                            position: absolute;
                            display: block;
                            pointer-events: none;
                            width: 10px;
                            height: 10px;
                            top: 3px;
                            right: 3px;
                            border-radius: 1px;
                            background-color: $color-green;
                            transform: scale(0);
                            transition: all 350ms ease;
                        }
                        &:checked:after {
                            transform: scale(1);
                        }
                    }
                    ul {
                        position: relative;
                        li {
                            position: relative;
                            display: flex;
                            align-items: center;
                            margin-bottom: 5px;
                            cursor: pointer;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            .price {
                                border-radius: 3px;
                                font-size: 12px;
                                font-weight: 700;
                                color: darken($color-yellow, 40%);
                                margin-left: 6px;
                                white-space: nowrap;
                            }
                        }
                    }
                    .grid {
                        position: relative;
                        display: flex;
                        flex-wrap: wrap;
                        margin: -10px;
                        .item {
                            position: relative;
                            width: 360px;
                            padding: 10px;
                            @media #{$breakpoint-huge} {
                                width: 50%;
                            }
                            @media #{$breakpoint-large} {
                                width: 100%;
                            }
                            @media #{$breakpoint-medium} {
                                width: 50%;
                            }
                            @media #{$breakpoint-small} {
                                width: 100%;
                            }
                            .box {
                                position: relative;
                                border: 1px solid #cccccc;
                                border-radius: 6px;
                                background-color: $color-white;
                                box-shadow: 0 2px 8px rgba(0, 0, 0, 0);
                                transition: all 350ms ease;
                                overflow: hidden;
                                cursor: pointer;
                                &:after {
                                    content: '';
                                    position: absolute;
                                    display: block;
                                    pointer-events: none;
                                    top: -1px;
                                    right: -1px;
                                    bottom: -1px;
                                    left: -1px;
                                    border: 3px solid $color-green;
                                    border-radius: 6px;
                                    opacity: 0;
                                    transition: all 350ms ease;
                                }
                                .image {
                                    position: relative;
                                    padding-bottom: 50%;
                                    background-color: lighten(#eeeeee, 4%);
                                    overflow: hidden;
                                    .holder {
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        bottom: 0;
                                        left: 0;
                                        img {
                                            width: 100%;
                                            height: 100%;
                                            object-fit: cover;
                                        }
                                    }
                                    .check {
                                        position: absolute;
                                        display: block;
                                        width: 24px;
                                        height: 24px;
                                        top: 30px;
                                        right: 30px;
                                        &:before {
                                            content: '';
                                            position: absolute;
                                            display: block;
                                            top: 0;
                                            right: 0;
                                            bottom: 0;
                                            left: 0;
                                            border-radius: 100%;
                                            transform: scale(6);
                                            background-color: rgba(0, 0, 0, 0.5);
                                            filter: blur(10px);
                                            opacity: 0;
                                            transition: all 350ms ease;
                                        }
                                        &:after {
                                            content: '';
                                            position: absolute;
                                            display: block;
                                            top: 0;
                                            right: 0;
                                            bottom: 0;
                                            left: 0;
                                            background-image: url('/images/icons/check.svg');
                                            background-repeat: no-repeat;
                                            background-size: contain;
                                            transform: translateX(-10px);
                                            transition: all 350ms ease;
                                            opacity: 0;
                                        }
                                    }
                                }
                                .inner {
                                    position: relative;
                                    padding: 30px;
                                    @media #{$breakpoint-medium} {
                                        padding: 20px;
                                    }
                                    .title {
                                        position: relative;
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        margin-bottom: 20px;
                                        h4 {
                                            font-size: 16px;
                                            font-weight: 400;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        }
                                        span {
                                            display: block;
                                            background-color: $color-yellow;
                                            height: 25px;
                                            padding: 0 5px;
                                            border-radius: 3px;
                                            line-height: 25px;
                                            font-size: 12px;
                                            font-weight: 700;
                                            color: rgba(0, 0, 0, 0.4);
                                            margin-left: 20px;
                                            white-space: nowrap;
                                        }
                                    }
                                    p {
                                        position: relative;
                                        line-height: 1.4em;
                                        font-weight: 300;
                                        color: #666666;
                                    }
                                    p,
                                    ul {
                                        input[type='checkbox'] {
                                            pointer-events: none;
                                        }
                                        margin-bottom: 20px;
                                        &:last-child {
                                            margin-bottom: 0;
                                        }
                                        &.details {
                                            line-height: 1.4em;
                                            font-weight: 300;
                                            font-size: 13px;
                                            li {
                                                margin-bottom: 0;
                                                padding-left: 22px;
                                                a {
                                                    color: $color-brand;
                                                    font-weight: 400;
                                                    border-bottom: 1px solid $color-brand;
                                                }
                                                &:after {
                                                    content: '';
                                                    position: absolute;
                                                    display: block;
                                                    width: 14px;
                                                    height: 14px;
                                                    top: 2px;
                                                    left: 0;
                                                    background-repeat: no-repeat;
                                                    background-size: contain;
                                                    opacity: 0.3;
                                                }
                                                &.persons:after {
                                                    background-image: url('/images/icons/user.svg');
                                                }
                                                &.size:after {
                                                    background-image: url('/images/icons/box.svg');
                                                }
                                                &.link:after {
                                                    background-image: url('/images/icons/link.svg');
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &:hover .box {
                                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
                                border: 1px solid $color-green;
                            }
                            &.disabled {
                                opacity: 0.5;
                                pointer-events: none;
                            }
                            &.active .box {
                                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
                                &:after {
                                    opacity: 1;
                                }
                                .image .check {
                                    &:before {
                                        opacity: 1;
                                    }
                                    &:after {
                                        transform: translateX(0);
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                }
                &.expandable {
                    cursor: pointer;
                    h3 {
                        padding-left: 20px;
                        &:before {
                            content: '+';
                            position: absolute;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 20px;
                            width: 10px;
                            left: 0;
                            top: 0;
                            font-size: 20px;
                            opacity: 0.25;
                            transition: transform 175ms ease-in-out;
                        }
                    }
                    .content,
                    p {
                        display: none;
                    }
                    &:hover h3:before {
                        opacity: 0.5;
                    }
                    &.expanded {
                        .content,
                        p {
                            display: block;
                        }
                        h3:before {
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
        }
    }
}
